import React from 'react'
import { Avatar } from '@revolut/ui-kit'

import {
  DocumentsTemplateFieldInterface,
  DocumentsTemplateDataFieldRecipientId,
  DocumentsTemplateDataFieldSourceId,
  DocumentsTemplatesInterface,
} from '@src/interfaces/documentsTemplates'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'

export const fieldTypes = ['text', 'number', 'date', 'money', 'signature'] as const
export type FieldType = typeof fieldTypes[number]

export interface BaseFieldInterface<T extends DocumentsTemplateFieldInterface> {
  id: string
  type: FieldType
  data: T
}

export interface ActiveFieldInterface {
  isActive: boolean
  setActive: () => void
  setInactive: () => void
}

export type TemplateField<T extends DocumentsTemplateFieldInterface> =
  BaseFieldInterface<T> & ActiveFieldInterface

export const mapTypeToAvatar: Record<FieldType, React.ReactNode> = {
  text: <Avatar useIcon="Text" />,
  number: <Avatar size={40} label="1" />,
  date: <Avatar useIcon="CalendarDate" />,
  money: <Avatar useIcon="Coins" />,
  signature: <Avatar useIcon="Pencil" />,
}

const defaultPosition = {
  width: 200,
  height: 24,
  x_position: 50,
  y_position: 50,
}

type CustomValueType = 'text' | 'number' | 'date' | 'money' | 'signature'

export const getSourceIdToLabel = (
  type?: CustomValueType,
): Record<DocumentsTemplateDataFieldSourceId, string> => ({
  custom_value:
    type === 'text'
      ? 'Custom Text'
      : type === 'number'
      ? 'Custom Number'
      : type === 'date'
      ? 'Custom Date'
      : type === 'money'
      ? 'Custom Amount'
      : 'Custom Value',
  sql_source: 'Revolut People Data',
  to_be_filled: 'To be filled in by Recipient',
})
const recipientIdToLabel: Record<DocumentsTemplateDataFieldRecipientId, string> = {
  employee: 'Employee',
  hr_manager: 'HR manager',
}

const getOptionsFromIdToLabel = <T extends string>(
  obj: Record<T, string>,
): RadioSelectOption<IdAndName<T>>[] =>
  Object.entries(obj).map(([id, name]) => {
    const value = { id, name } as IdAndName<T>
    return { id, label: name, value }
  })

export const getSourceOptions = (type?: CustomValueType) =>
  getOptionsFromIdToLabel<DocumentsTemplateDataFieldSourceId>(getSourceIdToLabel(type))
export const recipientOptions =
  getOptionsFromIdToLabel<DocumentsTemplateDataFieldRecipientId>(recipientIdToLabel)

export const addNewField = (
  values: DocumentsTemplatesInterface,
  fieldType: FieldType,
  pageNum: number,
) => {
  switch (fieldType) {
    case 'text': {
      values.text_fields = [
        ...values.text_fields,
        {
          ...defaultPosition,
          placeholder: 'Text field',
          page_number: pageNum,
          source_type: {
            id: 'custom_value',
            name: getSourceIdToLabel('text').custom_value,
          },
          recipient: { id: 'employee', name: recipientIdToLabel.employee },
          custom_value: 'Custom text',
          sql_source: null,
        },
      ]
      break
    }
    case 'number': {
      values.number_fields = [
        ...values.number_fields,
        {
          ...defaultPosition,
          placeholder: 'Number field',
          page_number: pageNum,
          source_type: {
            id: 'custom_value',
            name: getSourceIdToLabel('number').custom_value,
          },
          recipient: { id: 'employee', name: recipientIdToLabel.employee },
          custom_value: '12345',
          sql_source: null,
        },
      ]
      break
    }
    case 'date': {
      values.date_fields = [
        ...values.date_fields,
        {
          ...defaultPosition,
          placeholder: 'Date field',
          page_number: pageNum,
          source_type: {
            id: 'custom_value',
            name: getSourceIdToLabel('date').custom_value,
          },
          recipient: { id: 'employee', name: recipientIdToLabel.employee },
          custom_value: new Date().toISOString(),
          sql_source: null,
        },
      ]
      break
    }
    case 'money': {
      values.money_fields = [
        ...values.money_fields,
        {
          ...defaultPosition,
          placeholder: 'Money field',
          page_number: pageNum,
          source_type: {
            id: 'custom_value',
            name: getSourceIdToLabel('money').custom_value,
          },
          recipient: { id: 'employee', name: recipientIdToLabel.employee },
          currency: null,
          custom_value: 0,
          sql_source: null,
        },
      ]
      break
    }
    case 'signature': {
      values.signature_fields = [
        ...values.signature_fields,
        {
          ...defaultPosition,
          placeholder: 'Signature field',
          page_number: pageNum,
          source_type: {
            id: 'to_be_filled',
            name: getSourceIdToLabel('signature').to_be_filled,
          },
          recipient: { id: 'employee', name: recipientIdToLabel.employee },
          sql_source: null,
        },
      ]
      break
    }
    default:
      break
  }
}

export const getFieldsByPage = <T extends DocumentsTemplateFieldInterface>(
  fieldType: FieldType,
  pageNum: number | undefined,
  values: DocumentsTemplatesInterface,
): T[] => {
  const fields = values[`${fieldType}_fields` as const]

  if (!Array.isArray(fields)) {
    return []
  }
  if (pageNum === undefined) {
    return fields as T[]
  }
  return fields.filter(f => f.page_number === pageNum) as T[]
}

export const hasFieldsOnPage = (
  pageNum: number | undefined,
  values: DocumentsTemplatesInterface,
) =>
  fieldTypes
    .map(fieldType => getFieldsByPage(fieldType, pageNum, values).length)
    .some(Boolean)

export const hasAnyFields = (values: DocumentsTemplatesInterface) =>
  hasFieldsOnPage(undefined, values)

export const getIndexedFieldKey = (
  fieldType: FieldType,
  pageIdx: number | undefined,
  idx: number,
) => `${fieldType}_${pageIdx || 0}_${idx}`

export const getDataFromIndexedKey = (key: string) => {
  const [type, pageIdx, idx] = key.split('_')

  return { type, pageNum: Number(pageIdx) + 1, idx: Number(idx) }
}
