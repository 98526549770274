import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Button,
  Group,
  Input,
  InputGroup,
  Subheader,
  VStack,
  Text,
  Color,
  Box,
  useStatusPopup,
  StatusPopup,
  Item,
  Radio,
  Avatar,
} from '@revolut/ui-kit'
import { Card, LogoRevolut } from '@revolut/icons'
import RevolutCheckout, {
  RevolutCheckoutCardField,
  ValidationError,
} from '@revolut/checkout'
import { z } from 'zod'
import * as Sentry from '@sentry/react'

import { OrderAndConfig } from './SubscriptionPlanProvider'
import {
  getLatestInvoice,
  getSubscriptionInfo,
  refreshMerchantApiOrder,
} from '@src/api/plans'
import { AvailableSubscriptionPlanInterface } from '@src/interfaces/plans'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'

type PaymentMethod = 'revolut-pay' | 'card'

export type CheckoutWidgetType = 'change-payment-method' | 'subscribe'

type CardErrors = ValidationError[] | { message: string }[]

const NAME_REQUIRED_ERROR = 'This field may not be blank.'
const NAME_2_WORDS_ERROR = 'Cardholder name must be at least two words.'
const GENERIC_CARD_ERROR = 'Fill in the card details'

const validateName = (name: string) =>
  z
    .string()
    .trim()
    .min(1, { message: NAME_REQUIRED_ERROR })
    .refine(val => val.split(/\s+/).length >= 2, {
      message: NAME_2_WORDS_ERROR,
    })
    .safeParse(name)

const validator = {
  name: validateName,
}

interface CheckoutWidgetContextInterface {
  onValidate: (field: 'name', value: string) => void
  revolutCheckoutRef: React.MutableRefObject<RevolutCheckoutCardField | null>
  cardElementRef: React.MutableRefObject<HTMLInputElement | null>
  revolutPayRef: React.MutableRefObject<HTMLDivElement | null>
  hasNameErrors: boolean
  hasCardErrors: boolean
  cardStatusCompleted: boolean
  setCardStatusCompleted: (state: boolean) => void
  cardholderName: string
  setCardholderName: (state: string) => void
  submitPending: boolean
  setSubmitPending: (state: boolean) => void
  paymentMethod: PaymentMethod
  setPaymentMethod: (state: PaymentMethod) => void
  cardholderNameErrors: string[]
  cardErrors: CardErrors
  setCardErrors: (state: CardErrors) => void
  type: CheckoutWidgetType
}

const CheckoutWidgetContext = createContext<CheckoutWidgetContextInterface | null>(null)

const useCheckoutWidgetContext = () => {
  const context = useContext(CheckoutWidgetContext)
  if (context == null) {
    throw new Error(`useCheckoutWidgetContext must be used within CheckoutWidget`)
  }
  return context
}

interface CheckoutWidgetProps {
  orderAndConfig: OrderAndConfig
  initialPaymentMethod: PaymentMethod
  children: React.ReactNode
  onSuccessPopupClose: () => void
  onRetryPayment?: (invoiceId: number) => void
  plan?: AvailableSubscriptionPlanInterface
}

export const CheckoutWidget = ({
  orderAndConfig,
  initialPaymentMethod,
  onSuccessPopupClose,
  onRetryPayment,
  plan,
  children,
}: CheckoutWidgetProps) => {
  const revolutCheckoutRef = useRef<RevolutCheckoutCardField | null>(null)
  const cardElementRef = useRef<HTMLInputElement>(null)
  const revolutPayRef = useRef<HTMLDivElement>(null)

  const statusPopup = useStatusPopup()
  const { theme } = useAppTheme()

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(initialPaymentMethod)

  const [cardErrors, setCardErrors] = useState<CardErrors>([])
  const [cardStatusCompleted, setCardStatusCompleted] = useState(false)

  const [cardholderName, setCardholderName] = useState('')
  const [cardholderNameErrors, setCardholderNameErrors] = useState<string[]>([])

  const [submitPending, setSubmitPending] = useState(false)

  useEffect(() => {
    if (!orderAndConfig) {
      return undefined
    }

    let destroyRevolutPay = () => {}

    const onClose = () => {
      statusPopup.hide()
      onSuccessPopupClose()
    }

    const onSuccess = async () => {
      let invoiceToBeChargedId: number | null = null

      try {
        const refreshMerchantApiOrderResponse = await refreshMerchantApiOrder(
          orderAndConfig.order.id,
        )
        const merchantOrderCompleted =
          refreshMerchantApiOrderResponse.data.revolut_merchant_api_order.state?.id ===
          'COMPLETED'

        if (
          merchantOrderCompleted &&
          orderAndConfig.type === 'change-payment-method' &&
          !!onRetryPayment
        ) {
          const [latestInvoiceResponse, subscriptionInfoResponse] = await Promise.all([
            getLatestInvoice(),
            getSubscriptionInfo(),
          ])
          if (
            latestInvoiceResponse.data?.status.id === 'open' &&
            subscriptionInfoResponse?.data.last_payment_status?.id === 'failure'
          ) {
            invoiceToBeChargedId = latestInvoiceResponse.data.id
          }
        }
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
      } finally {
        if (invoiceToBeChargedId) {
          onRetryPayment?.(invoiceToBeChargedId)
        } else {
          statusPopup.show(
            <StatusPopup variant="success-result" onClose={onClose}>
              <StatusPopup.Title>
                {orderAndConfig.type === 'change-payment-method'
                  ? 'Success'
                  : `${orderAndConfig.order.subscription_plan.name} plan has started`}
              </StatusPopup.Title>
              {orderAndConfig.type === 'change-payment-method' ? (
                <StatusPopup.Description>
                  Your payment details have been updated
                </StatusPopup.Description>
              ) : null}
              {orderAndConfig.type === 'subscribe' &&
              plan?.subscription_plan.free_days ? (
                <StatusPopup.Description>
                  Enjoy a {plan?.subscription_plan.free_days}-day trial period, completely
                  free. You'll only be charged once the trial ends.
                </StatusPopup.Description>
              ) : null}
              <StatusPopup.Actions>
                <Button onClick={onClose} variant="secondary">
                  Close
                </Button>
              </StatusPopup.Actions>
            </StatusPopup>,
          )
        }
      }
    }

    const onError = (message: string) => {
      refreshMerchantApiOrder(orderAndConfig.order.id)
      setSubmitPending(false)

      statusPopup.show(
        <StatusPopup variant="error" onClose={() => statusPopup.hide()}>
          <StatusPopup.Title>Something went wrong</StatusPopup.Title>
          <StatusPopup.Description>{message}</StatusPopup.Description>
          <StatusPopup.Actions>
            <Button elevated onClick={() => statusPopup.hide()}>
              Try again
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }

    const onCancel = () => {
      refreshMerchantApiOrder(orderAndConfig.order.id)
      setSubmitPending(false)
    }

    if (paymentMethod === 'card') {
      RevolutCheckout(
        orderAndConfig.order.revolut_merchant_api_order.public_id,
        orderAndConfig.config.mode,
      ).then(RC => {
        if (!cardElementRef.current) {
          return
        }

        revolutCheckoutRef.current = RC.createCardField({
          target: cardElementRef.current,
          hidePostcodeField: true,
          styles: {
            default: {
              color: theme === 'dark' ? 'white' : 'black',
            },
          },
          onValidation(errors) {
            setCardErrors(errors)
          },
          onSuccess() {
            onSuccess()
          },
          onError(error) {
            onError(error.message)
          },
          onCancel() {
            onCancel()
          },
          onStatusChange(status) {
            setCardStatusCompleted(status.completed)
          },
        })
      })
    }

    if (paymentMethod === 'revolut-pay') {
      if (!revolutPayRef.current) {
        return undefined
      }

      RevolutCheckout.payments({
        locale: 'en',
        publicToken: orderAndConfig.config.public_key,
        mode: orderAndConfig.config.mode,
      }).then(({ revolutPay, destroy }) => {
        destroyRevolutPay = destroy

        revolutPay.mount(revolutPayRef.current, {
          // @ts-ignore this property is required for our subscriptions to work, it's in their docs even, but not in type definition
          savePaymentMethodForMerchant: true,
          currency:
            orderAndConfig?.order?.revolut_merchant_api_order?.order_amount.currency,
          totalAmount:
            orderAndConfig?.order?.revolut_merchant_api_order?.order_amount.value,
          buttonStyle: {
            height: '48px',
            radius: 'large',
            cashback: false,
          },
          createOrder: () => {
            return Promise.resolve({
              publicId: orderAndConfig.order.revolut_merchant_api_order.public_id,
            })
          },
        })

        revolutPay.on('payment', event => {
          if (event.type === 'success') {
            onSuccess()
          }
          if (event.type === 'cancel') {
            onCancel()
          }
        })
      })
    }

    return () => {
      destroyRevolutPay()
      revolutCheckoutRef.current?.destroy()
    }
  }, [orderAndConfig, paymentMethod])

  const hasNameErrors = cardholderNameErrors.length > 0
  const hasCardErrors = cardErrors.length > 0

  const onValidate = (field: 'name', value: string) => {
    const validationResult = validator[field](value)
    const setField = {
      name: setCardholderNameErrors,
    }[field]

    if (validationResult.success) {
      setField([])
    } else {
      setField(validationResult.error.issues.map(issue => issue.message))
    }
  }

  const value = useMemo(
    () => ({
      hasNameErrors,
      hasCardErrors,
      onValidate,
      cardStatusCompleted,
      setCardStatusCompleted,
      revolutCheckoutRef,
      cardElementRef,
      revolutPayRef,
      cardholderName,
      setCardholderName,
      type: orderAndConfig.type,
      submitPending,
      setSubmitPending,
      paymentMethod,
      setPaymentMethod,
      cardErrors,
      setCardErrors,
      cardholderNameErrors,
    }),
    [
      hasNameErrors,
      hasCardErrors,
      onValidate,
      cardStatusCompleted,
      setCardStatusCompleted,
      cardholderName,
      setCardholderName,
      orderAndConfig.type,
      submitPending,
      setSubmitPending,
      paymentMethod,
      setPaymentMethod,
      cardErrors,
      setCardErrors,
      cardholderNameErrors,
    ],
  )

  return (
    <CheckoutWidgetContext.Provider value={value}>
      {children}
    </CheckoutWidgetContext.Provider>
  )
}

const Details = () => {
  const {
    hasNameErrors,
    onValidate,
    cardholderName,
    setCardholderName,
    setPaymentMethod,
    paymentMethod,
    cardElementRef,
    cardErrors,
    cardholderNameErrors,
  } = useCheckoutWidgetContext()

  const onInputFieldChange = (field: 'name', value: string) => {
    const fieldHasErrors = {
      name: hasNameErrors,
    }[field]

    const setField = {
      name: setCardholderName,
    }[field]

    setField(value)

    if (fieldHasErrors) {
      onValidate(field, value)
    }
  }

  return (
    <>
      <Subheader variant="default">
        <Subheader.Title>Payment method</Subheader.Title>
      </Subheader>

      <Group>
        <Item use="label">
          <Item.Prefix>
            <Radio
              onClick={() => setPaymentMethod('revolut-pay')}
              checked={paymentMethod === 'revolut-pay'}
              aria-labelledby="revolut-pay"
            />
          </Item.Prefix>
          <Item.Avatar>
            <Avatar
              useIcon={LogoRevolut}
              bg={Color.FOREGROUND}
              color={Color.BACKGROUND}
            />
          </Item.Avatar>
          <Item.Content>
            <Item.Title id="revolut-pay">Revolut Pay</Item.Title>
          </Item.Content>
        </Item>
        <Item use="label">
          <Item.Prefix>
            <Radio
              onClick={() => setPaymentMethod('card')}
              checked={paymentMethod === 'card'}
              aria-labelledby="card"
            />
          </Item.Prefix>
          <Item.Avatar>
            <Avatar useIcon={Card} />
          </Item.Avatar>
          <Item.Content>
            <Item.Title id="card">Debit or Credit Card</Item.Title>
          </Item.Content>
        </Item>
      </Group>

      {paymentMethod === 'card' ? (
        <>
          <Box mt="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Payment details</Subheader.Title>
            </Subheader>
          </Box>

          <InputGroup>
            <Input
              ref={cardElementRef}
              use="span"
              aria-invalid={cardErrors.length > 0}
              message={<ErrorMessage errors={cardErrors.map(error => error.message)} />}
            />
            <Input
              label="Cardholder name"
              value={cardholderName}
              onChange={e => onInputFieldChange('name', e.currentTarget.value)}
              aria-invalid={hasNameErrors}
              message={<ErrorMessage errors={cardholderNameErrors} />}
              onBlur={e => onValidate('name', e.currentTarget.value)}
            />
          </InputGroup>
        </>
      ) : null}
    </>
  )
}

const Actions = () => {
  const statusPopup = useStatusPopup()

  const {
    cardStatusCompleted,
    hasCardErrors,
    hasNameErrors,
    onValidate,
    cardholderName,
    setSubmitPending,
    submitPending,
    revolutCheckoutRef,
    revolutPayRef,
    paymentMethod,
    setCardErrors,
    type,
  } = useCheckoutWidgetContext()

  const formInvalid =
    !cardholderName.length || !cardStatusCompleted || hasNameErrors || hasCardErrors

  const onSubmit = () => {
    if (formInvalid) {
      onValidate('name', cardholderName)
      if (!cardStatusCompleted && !hasCardErrors) {
        setCardErrors([{ message: GENERIC_CARD_ERROR }])
      }
      return
    }

    if (revolutCheckoutRef.current && !submitPending) {
      setSubmitPending(true)

      revolutCheckoutRef.current.submit({
        name: cardholderName,
        savePaymentMethodFor: 'merchant',
      })

      statusPopup.show(
        <StatusPopup variant="loading" preventUserClose>
          <StatusPopup.Title>
            {type === 'subscribe' ? 'Placing order' : 'Updating payment details'}
          </StatusPopup.Title>
          <StatusPopup.Description>
            This should take a couple of seconds
          </StatusPopup.Description>
        </StatusPopup>,
      )
    }
  }

  return (
    <>
      {paymentMethod === 'card' && (
        <Button onClick={onSubmit} disabled={submitPending} elevated>
          {type === 'subscribe' ? 'Place order' : 'Save'}
        </Button>
      )}
      {paymentMethod === 'revolut-pay' && <Box ref={revolutPayRef} />}
    </>
  )
}

interface ErrorMessageProps {
  errors: string[]
}

const ErrorMessage = ({ errors }: ErrorMessageProps) => {
  return (
    <VStack>
      {errors.map(error => (
        <Text key={error}>{error}</Text>
      ))}
    </VStack>
  )
}

CheckoutWidget.Details = Details
CheckoutWidget.Actions = Actions
