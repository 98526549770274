import React from 'react'
import capitalize from 'lodash/capitalize'
import {
  Avatar,
  Box,
  Cell,
  Icon,
  Input,
  Item,
  Subheader,
  Token,
  VStack,
} from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DocumentsTemplateFieldInterface,
  DocumentsTemplateDateFieldInterface,
  DocumentsTemplateMoneyFieldInterface,
  DocumentsTemplateNumberFieldInterface,
  DocumentsTemplatesInterface,
  DocumentsTemplateTextFieldInterface,
} from '@src/interfaces/documentsTemplates'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import NewDatePicker from '@components/Inputs/NewDatePicker/NewDatePicker'
import { selectorKeys } from '@src/constants/api'

import {
  ActiveFieldInterface,
  TemplateField,
  getFieldsByPage,
  getIndexedFieldKey,
  getSourceIdToLabel,
  getSourceOptions,
  hasFieldsOnPage,
  mapTypeToAvatar,
  recipientOptions,
  BaseFieldInterface,
  getDataFromIndexedKey,
  hasAnyFields,
} from './common'

type FieldSettingsProps<T extends DocumentsTemplateFieldInterface> =
  React.PropsWithChildren<TemplateField<T>>

const FieldSettings = <T extends DocumentsTemplateFieldInterface>({
  id,
  type,
  data,
  isActive,
  setActive,
  setInactive,
  children,
}: FieldSettingsProps<T>) => {
  const { idx } = getDataFromIndexedKey(id)

  return (
    <VStack space="s-4">
      <Item use="button" type="button" onClick={isActive ? setInactive : setActive}>
        <Item.Avatar>{mapTypeToAvatar[type]}</Item.Avatar>
        <Item.Content>
          <Item.Title>
            {capitalize(type)} field {idx + 1} settings
          </Item.Title>
        </Item.Content>
        <Item.Side>
          <Icon name={isActive ? 'ChevronUp' : 'ChevronDown'} />
        </Item.Side>
      </Item>
      {isActive && (
        <VStack p="s-4" pt={0} space="s-4">
          {type !== 'signature' && <DataSourceSelector id={id} type={type} data={data} />}
          {data.source_type.id === 'to_be_filled' && (
            <>
              <Input
                label="Field label"
                value={data.placeholder}
                onChange={e => {
                  data.placeholder = e.currentTarget.value
                }}
              />
              <RadioSelectInput
                label="Recipient"
                options={recipientOptions}
                value={data.recipient}
                onChange={newValue => {
                  if (newValue) {
                    data.recipient = newValue
                  }
                }}
                searchable={false}
              />
            </>
          )}
          {children}
        </VStack>
      )}
    </VStack>
  )
}

const DataSourceSelector = <T extends DocumentsTemplateFieldInterface>({
  type,
  data,
}: BaseFieldInterface<T>) => {
  return (
    <RadioSelectInput
      label="Data source"
      options={getSourceOptions(type)}
      value={data.source_type}
      onChange={newValue => {
        if (newValue) {
          data.source_type = newValue
        }
      }}
      searchable={false}
    />
  )
}

type TypedFieldSettingsProps<T extends DocumentsTemplateFieldInterface> = Omit<
  FieldSettingsProps<T>,
  'type'
>

const TextFieldSettings = (
  props: TypedFieldSettingsProps<DocumentsTemplateTextFieldInterface>,
) => {
  const { data } = props
  return (
    <FieldSettings<DocumentsTemplateTextFieldInterface> type="text" {...props}>
      {data.source_type.id === 'custom_value' && (
        <Input
          label={getSourceIdToLabel('text').custom_value}
          value={data.custom_value}
          onChange={e => {
            data.custom_value = e.currentTarget.value
          }}
        />
      )}
    </FieldSettings>
  )
}

const NumberFieldSettings = (
  props: TypedFieldSettingsProps<DocumentsTemplateNumberFieldInterface>,
) => {
  const { data } = props
  return (
    <FieldSettings<DocumentsTemplateFieldInterface> type="number" {...props}>
      {data.source_type.id === 'custom_value' && (
        <Input
          type="number"
          label={getSourceIdToLabel('number').custom_value}
          value={data.custom_value}
          onChange={e => {
            data.custom_value = e.currentTarget.value
          }}
        />
      )}
    </FieldSettings>
  )
}

const DateFieldSettings = (
  props: TypedFieldSettingsProps<DocumentsTemplateDateFieldInterface>,
) => {
  const { data } = props
  return (
    <FieldSettings<DocumentsTemplateFieldInterface> type="date" {...props}>
      {data.source_type.id === 'custom_value' && (
        <NewDatePicker
          label={getSourceIdToLabel('date').custom_value}
          value={data.custom_value}
          onChange={newDate => {
            if (newDate) {
              data.custom_value = newDate.toISOString()
            }
          }}
        />
      )}
    </FieldSettings>
  )
}

const MoneyFieldSettings = (
  props: TypedFieldSettingsProps<DocumentsTemplateMoneyFieldInterface>,
) => {
  const { data } = props
  return (
    <FieldSettings<DocumentsTemplateMoneyFieldInterface> {...props} type="money">
      {data.source_type.id === 'custom_value' && (
        <>
          <Input
            type="money"
            label={getSourceIdToLabel('money').custom_value}
            value={data.custom_value}
            onChange={newValue => {
              if (newValue != null) {
                data.custom_value = newValue
              }
            }}
          />
          <RadioSelectInput
            label="Currency"
            value={data.currency}
            selector={selectorKeys.currencies}
            onChange={newValue => {
              if (newValue) {
                data.currency = newValue
              }
            }}
          />
        </>
      )}
    </FieldSettings>
  )
}

const SignatureFieldSettings = (
  props: TypedFieldSettingsProps<DocumentsTemplateFieldInterface>,
) => {
  return <FieldSettings<DocumentsTemplateFieldInterface> type="signature" {...props} />
}

type Props = {
  totalPages?: number
  activeFieldKey: string | undefined
  setActiveFieldKey: (id: string | undefined) => void
}
export const FieldsSettingsBar = ({
  totalPages,
  activeFieldKey,
  setActiveFieldKey,
}: Props) => {
  const { values } = useLapeContext<DocumentsTemplatesInterface>()

  const getIsActiveProps = (key: string): ActiveFieldInterface => ({
    isActive: key === activeFieldKey,
    setActive: () => setActiveFieldKey(key),
    setInactive: () => setActiveFieldKey(undefined),
  })

  if (!hasAnyFields(values)) {
    return (
      <Item>
        <Item.Avatar>
          <Avatar useIcon="ExclamationTriangle" color={Token.color.blue} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>No any fields have been added yet</Item.Title>
          <Item.Description>Click "Add data field" to create a new one</Item.Description>
        </Item.Content>
      </Item>
    )
  }
  return (
    <VStack maxHeight="calc(100vh - 100px)" overflow="scroll" pr="s-4">
      {[...Array(totalPages).keys()].map(pageIdx => {
        const pageNum = pageIdx + 1

        if (!hasFieldsOnPage(pageNum, values)) {
          return null
        }
        return (
          <Box key={pageIdx}>
            <Subheader variant="nested">
              <Subheader.Title>Page {pageNum}</Subheader.Title>
            </Subheader>
            <Cell p="s-4">
              <VStack space="s-8" width="100%" overflow="scroll">
                {getFieldsByPage<DocumentsTemplateTextFieldInterface>(
                  'text',
                  pageNum,
                  values,
                ).map((field, idx) => {
                  const key = getIndexedFieldKey('text', pageIdx, idx)
                  return (
                    <TextFieldSettings
                      id={key}
                      key={key}
                      data={field}
                      {...getIsActiveProps(key)}
                    />
                  )
                })}
                {getFieldsByPage<DocumentsTemplateNumberFieldInterface>(
                  'number',
                  pageNum,
                  values,
                ).map((field, idx) => {
                  const key = getIndexedFieldKey('number', pageIdx, idx)
                  return (
                    <NumberFieldSettings
                      id={key}
                      key={key}
                      data={field}
                      {...getIsActiveProps(key)}
                    />
                  )
                })}
                {getFieldsByPage<DocumentsTemplateDateFieldInterface>(
                  'date',
                  pageNum,
                  values,
                ).map((field, idx) => {
                  const key = getIndexedFieldKey('date', pageIdx, idx)
                  return (
                    <DateFieldSettings
                      id={key}
                      key={key}
                      data={field}
                      {...getIsActiveProps(key)}
                    />
                  )
                })}
                {getFieldsByPage<DocumentsTemplateMoneyFieldInterface>(
                  'money',
                  pageNum,
                  values,
                ).map((field, idx) => {
                  const key = getIndexedFieldKey('money', pageIdx, idx)
                  return (
                    <MoneyFieldSettings
                      id={key}
                      key={key}
                      data={field}
                      {...getIsActiveProps(key)}
                    />
                  )
                })}
                {getFieldsByPage('signature', pageNum, values).map((field, idx) => {
                  const key = getIndexedFieldKey('signature', pageIdx, idx)
                  return (
                    <SignatureFieldSettings
                      id={key}
                      key={key}
                      data={field}
                      {...getIsActiveProps(key)}
                    />
                  )
                })}
              </VStack>
            </Cell>
          </Box>
        )
      })}
    </VStack>
  )
}
