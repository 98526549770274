import { apiWithoutHandling } from '@src/api'
import * as Sentry from '@sentry/react'
import { UseFetchResult } from '@src/interfaces'
import { useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import {
  OnboardingCheckpointCategory,
  OnboardingCheckpointInterface,
} from '@src/interfaces/onboardingChecklist'
import {
  companyPreferences as companyPreferencesApi,
  useGetCompanyPreferences,
} from './settings'
import { useQueryClient } from 'react-query'
import { useSubsciptionInfo } from './plans'

type UseGetOnboardingCheckpoints = Pick<
  UseFetchResult<{ results: OnboardingCheckpointInterface[] }>,
  'isError' | 'isLoading'
> & {
  checkpoints?: OnboardingCheckpointInterface[]
}

const checkpointToSectionMap: Record<
  OnboardingCheckpointCategory,
  'hr_apps_to_setup' | 'performance_apps_to_setup' | 'recruitment_apps_to_setup' | null
> = {
  timeOff: 'hr_apps_to_setup',
  documents: 'hr_apps_to_setup',
  contracts: 'hr_apps_to_setup',
  lifecycle: 'hr_apps_to_setup',
  payroll: 'hr_apps_to_setup',
  performance: 'performance_apps_to_setup',
  skills: 'performance_apps_to_setup',
  goals: 'performance_apps_to_setup',
  roadmaps: 'performance_apps_to_setup',
  probation: 'performance_apps_to_setup',
  requisitions: 'recruitment_apps_to_setup',
  jobPostings: 'recruitment_apps_to_setup',
  hiring: 'recruitment_apps_to_setup',
  careers: 'recruitment_apps_to_setup',
  candidates: 'recruitment_apps_to_setup',
  initialSetup: null,
  upgradeToPaidPlan: null,
  departmentsTeams: null,
  roles: null,
  countries: null,
  finish: null,
  importEmployees: null,
}

export const useGetOnboardingCheckpoints = (): UseGetOnboardingCheckpoints => {
  const { data: onboardingCheckpoints, ...onboardingCheckpointsCtx } = useFetch<{
    results: OnboardingCheckpointInterface[]
  }>(API.ONBOARDING_CHECKPOINTS)
  const { data: companyPreferences, ...companyPreferencesCtx } =
    useGetCompanyPreferences()
  const subscriptionInfo = useSubsciptionInfo()

  const isLoading =
    onboardingCheckpointsCtx.isLoading ||
    companyPreferencesCtx.isLoading ||
    subscriptionInfo.isLoading
  const isError =
    onboardingCheckpointsCtx.isError ||
    companyPreferencesCtx.isError ||
    subscriptionInfo.isError

  if (isLoading || isError || !onboardingCheckpoints || !companyPreferences) {
    return { isLoading, isError }
  }

  const noPaymentMethod =
    subscriptionInfo.info?.revolut_merchant_api_payment_method === null

  const initialSetupCheckpoint = onboardingCheckpoints.results.find(
    checkpoint => checkpoint.category === 'initialSetup',
  )
  const importEmployeesCheckpoint = onboardingCheckpoints.results.find(
    checkpoint => checkpoint.category === 'importEmployees',
  )
  const { selected, ...appsToSetup } = {
    ...companyPreferences.hr_apps_to_setup,
    ...companyPreferences.performance_apps_to_setup,
    ...companyPreferences.recruitment_apps_to_setup,
  }

  /** Should never happen */
  if (!initialSetupCheckpoint || !importEmployeesCheckpoint) {
    const error =
      'API misconfigured, initialSetupCheckpoint or importEmployeesCheckpoint not found'
    Sentry.captureException(`[useGetOnboardingCheckpoints]: ${error}`)
    console.error(error)
    return { isLoading, isError, checkpoints: [] }
  }

  const apiCheckpoints = onboardingCheckpoints.results.map<OnboardingCheckpointInterface>(
    checkpoint => {
      const checkIsCheckpointVisible = () => {
        const checkpointEnabled =
          appsToSetup[checkpoint.category as keyof typeof appsToSetup]
        const checkpointSection = checkpointToSectionMap[checkpoint.category]
        const checkpointSectionEnabled = checkpointSection
          ? companyPreferences[checkpointSection].selected
          : false
        return !!(checkpointEnabled && checkpointSectionEnabled)
      }

      if (
        initialSetupCheckpoint.state.id !== 'completed' &&
        checkpoint.category !== 'initialSetup'
      ) {
        if (checkpoint.is_mandatory || checkpoint.category === 'finish') {
          return { ...checkpoint, state: { id: 'locked', name: 'Locked' } }
        }
        return { ...checkpoint, state: { id: 'hidden', name: 'Hidden' } }
      }

      if (
        importEmployeesCheckpoint.state.id !== 'completed' &&
        checkpoint.category !== 'initialSetup' &&
        checkpoint.category !== 'importEmployees'
      ) {
        if (checkpoint.is_mandatory || checkpoint.category === 'finish') {
          return { ...checkpoint, state: { id: 'locked', name: 'Locked' } }
        }

        return {
          ...checkpoint,
          state: checkIsCheckpointVisible()
            ? { id: 'locked', name: 'Locked' }
            : { id: 'hidden', name: 'Hidden' },
        }
      }

      return checkpoint.is_mandatory ||
        checkIsCheckpointVisible() ||
        checkpoint.category === 'finish'
        ? checkpoint
        : { ...checkpoint, state: { id: 'hidden', name: 'Hidden' } }
    },
  )

  /** BE doesn't send this checkpoint, we get info about payments from a different endpoint */
  const upgradeToPaidPlanCheckpoint: OnboardingCheckpointInterface = {
    category: 'upgradeToPaidPlan',
    current_step: null,
    state: { id: 'not_started', name: 'Not Started' },
    started_date_time: null,
    completed_date_time: null,
    is_mandatory: true,
  }

  const checkpoints = [
    upgradeToPaidPlanCheckpoint,
    ...apiCheckpoints,
  ].map<OnboardingCheckpointInterface>(checkpoint => {
    if (
      noPaymentMethod &&
      checkpoint.category !== 'upgradeToPaidPlan' &&
      checkpoint.state.id !== 'hidden'
    ) {
      return { ...checkpoint, state: { id: 'locked', name: 'Locked' } }
    }

    if (checkpoint.category === 'upgradeToPaidPlan' && !noPaymentMethod) {
      return { ...checkpoint, state: { id: 'completed', name: 'Completed' } }
    }

    return checkpoint
  })

  return {
    isLoading,
    isError,
    checkpoints,
  }
}

export const useGetOnboardingCheckpointCategory = (
  category: OnboardingCheckpointCategory,
) => {
  const response = useGetOnboardingCheckpoints()

  return {
    ...response,
    data: response.checkpoints?.find(checkpoint => checkpoint.category === category),
  }
}

export const useInvalidateOnboardingCheckpoints = () => {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries(API.ONBOARDING_CHECKPOINTS)
    queryClient.invalidateQueries(API.COMPANY_PREFERENCES)
  }
}

export const updateOnboardingCheckpointCategoryCurrentStep = (
  category: OnboardingCheckpointCategory,
  current_step: string,
) =>
  apiWithoutHandling.patch(`${API.ONBOARDING_CHECKPOINTS}/${category}`, {
    current_step,
  })

export const completeOnboardingCheckpointCategory = (
  category: OnboardingCheckpointCategory,
) => apiWithoutHandling.post(`${API.ONBOARDING_CHECKPOINTS}/${category}/complete`)

// For development and testing only
export const resetOnboardingCheckpoint = (category: OnboardingCheckpointCategory) =>
  apiWithoutHandling.post(`${API.ONBOARDING_CHECKPOINTS}/${category}/reset`)

// For development and testing only
export const resetInitialSetupCheckpoint = () =>
  Promise.all([
    resetOnboardingCheckpoint('initialSetup'),
    companyPreferencesApi.update(
      {
        tools: {},
        other_tools: [],
        other_tools_text: null,
        hr_apps_to_setup: {},
        performance_apps_to_setup: {},
        recruitment_apps_to_setup: {},
        enable_departments: true,
        enable_multi_specialisations: false,
        enable_functions: false,
      },
      { id: '1' },
    ),
  ])
