import React, { useRef } from 'react'
import { DndContext, useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { Absolute, Box, Flex, Text, Token } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DocumentsTemplateDateFieldInterface,
  DocumentsTemplateFieldInterface,
  DocumentsTemplateMoneyFieldInterface,
  DocumentsTemplatesInterface,
  DocumentsTemplateTextFieldInterface,
} from '@src/interfaces/documentsTemplates'
import { formatDate, formatMoney } from '@src/utils/format'
import { PdfPreviewScale } from '@src/features/PdfPreview/common'
import { TemplateField, fieldTypes, getFieldsByPage, getIndexedFieldKey } from './common'

type FieldAreaProps<T extends DocumentsTemplateFieldInterface> = TemplateField<T> &
  Pick<DragAndDropOverlayProps, 'scale'>

const Draggable = <T extends DocumentsTemplateFieldInterface>({
  id,
  data,
  scale,
  children,
}: React.PropsWithChildren<FieldAreaProps<T>>) => {
  const { setNodeRef, active, listeners, attributes, transform } = useDraggable({
    id,
    data,
  })
  const scaled = (size: number) => size * scale.value

  const isActive = active?.id === id
  const positionProps = isActive
    ? {
        style: {
          cursor: 'grabbing',
          boxShadow: `1px 1px 1px ${Token.color.greyTone20}`,
          transform: CSS.Translate.toString({
            x: -1 + scaled(data.x_position) + (transform?.x || 0),
            y: -1 + scaled(data.y_position) + (transform?.y || 0),

            scaleX: 1,
            scaleY: 1,
          }),
        },
      }
    : {
        style: { cursor: 'grab' },
        top: scaled(data.y_position),
        left: scaled(data.x_position),
      }
  return (
    <Absolute ref={setNodeRef} {...listeners} {...attributes} {...positionProps}>
      {children}
    </Absolute>
  )
}

const Resizeable = <T extends DocumentsTemplateFieldInterface>({
  data,
  scale,
  children,
}: React.PropsWithChildren<FieldAreaProps<T>>) => {
  return (
    <Box width={data.width * scale.value} height={data.height * scale.value}>
      {children}
    </Box>
  )
}

const FieldPreview = <T extends DocumentsTemplateFieldInterface>(
  props: FieldAreaProps<T>,
) => {
  const { isActive, type, data, scale } = props

  const formatFieldCustomValue = () => {
    switch (type) {
      case 'date': {
        const fieldData = data as unknown as DocumentsTemplateDateFieldInterface
        return formatDate(fieldData.custom_value)
      }
      case 'money': {
        const fieldData = data as unknown as DocumentsTemplateMoneyFieldInterface
        return formatMoney(fieldData.custom_value, fieldData.currency?.iso_code)
      }
      default: {
        const fieldData = data as unknown as DocumentsTemplateTextFieldInterface
        return fieldData.custom_value
      }
    }
  }

  const getLabel = () => {
    switch (data.source_type.id) {
      case 'to_be_filled':
        return data.placeholder
      case 'custom_value':
        return formatFieldCustomValue()
      case 'sql_source':
        return 'Platform data'
      default:
        return 'Unknown'
    }
  }

  const scaledFontPx = Math.floor(scale.value * data.height * 0.6)

  return (
    <Draggable {...props}>
      <Resizeable {...props}>
        {scaledFontPx > 2 && (
          <Flex
            style={{ lineHeight: 'unset' }}
            height="100%"
            alignItems="center"
            justifyContent="center"
            border={`1px solid ${Token.color.blue_50}`}
            bg={isActive ? Token.color.blue_20 : Token.color.blue_5}
          >
            <Text
              style={{ fontSize: `${scaledFontPx}px` }}
              color={Token.color.foreground}
            >
              {getLabel()}
            </Text>
          </Flex>
        )}
      </Resizeable>
    </Draggable>
  )
}

type DragAndDropOverlayProps = {
  pageNum: number | undefined
  scale: PdfPreviewScale
  width: number | undefined
  height: number | undefined
  activeFieldKey: string | undefined
  setActiveFieldKey: (newKey: string | undefined) => void
}
export const DragAndDropOverlay = ({
  pageNum = 1,
  scale,
  width,
  height,
  activeFieldKey,
  setActiveFieldKey,
}: DragAndDropOverlayProps) => {
  const overlayRef = useRef<HTMLDivElement>(null)
  const positionBufferRef = useRef<{ x: number | undefined; y: number | undefined }>({
    x: undefined,
    y: undefined,
  })
  const overlay = overlayRef.current
  const positionBuffer = positionBufferRef.current

  const { values } = useLapeContext<DocumentsTemplatesInterface>()

  return (
    <Absolute ref={overlayRef} width={width} height={height}>
      <DndContext
        autoScroll={{ enabled: false }}
        modifiers={[restrictToParentElement]}
        onDragStart={e => {
          setActiveFieldKey(e.active.id)
        }}
        onDragMove={e => {
          const { translated } = e.active.rect.current

          if (!positionBuffer || !overlay || !translated) {
            return
          }
          positionBuffer.x =
            (translated.left - overlay.getBoundingClientRect()?.left) / scale.value
          positionBuffer.y =
            (translated.top - overlay.getBoundingClientRect()?.top) / scale.value
        }}
        onDragEnd={e => {
          const fieldData = e.active.data.current as DocumentsTemplateFieldInterface

          if (
            !fieldData ||
            positionBuffer.x === undefined ||
            positionBuffer.y === undefined
          ) {
            return
          }
          fieldData.x_position = Math.round(positionBuffer.x)
          fieldData.y_position = Math.round(positionBuffer.y)
        }}
      >
        {fieldTypes.map(fieldType => (
          <React.Fragment key={fieldType}>
            {getFieldsByPage(fieldType, pageNum, values).map((fieldData, idx) => {
              const key = getIndexedFieldKey(fieldType, pageNum - 1, idx)

              return (
                <FieldPreview
                  id={key}
                  key={key}
                  type={fieldType}
                  data={fieldData}
                  scale={scale}
                  isActive={key === activeFieldKey}
                  setActive={() => setActiveFieldKey(key)}
                  setInactive={() => setActiveFieldKey(undefined)}
                />
              )
            })}
          </React.Fragment>
        ))}
      </DndContext>
    </Absolute>
  )
}
